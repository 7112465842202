import React from 'react'
import PropTypes from 'prop-types'
import { Container } from 'reactstrap'
import { withTheme } from 'styled-components'
import MastWaves from 'components/MastWaves'

const PageHeader = ({ waves, theme, children }) => {
  const color = theme.vstui.color.primary

  return (
    <div className="courses-mast" style={{ backgroundColor: color.getShade(7) }}>
      <Container>{children}</Container>
      {waves && <MastWaves />}
    </div>
  )
}

PageHeader.propTypes = {
  theme: PropTypes.object,
  waves: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

export default withTheme(PageHeader)
