import React, { memo, useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'
import IframeResizer from 'iframe-resizer-react'
import { Modal } from 'components'
import { useSelectedAssignmentData } from 'utils/customHooks'
import { StoreContext } from 'store'
import { actionHasErrors, isActionLoaded } from 'store/selectors'
import { resetAction } from 'store/actions'
import { useSettingsContext } from 'pages/Settings/store/settingsContext'
import { openExceptionsSettingsDrawer } from 'pages/Settings/store/actions'
import Spinner from 'components/Spinner'

const AssignmentModal = ({ assignment, closeModal, courseKey, onAssignmentEdit }) => {
  const { api, assignments, dispatch } = useContext(StoreContext)
  const { dispatchSettingsAction } = useSettingsContext()
  const [assignmentIdAndIdentifier, setAssignmentIdAndIdentifier] = useState()
  const [isLoadingInstructorSettings, setIsLoadingInstructorSettings] = useState(false)

  const iframeRef = useRef(null)
  const [getSelectedAssignmentSettingsData, getSelectedAssignmentExceptionsData] =
    useSelectedAssignmentData({
      assignments,
      courseKey,
    })

  const iframeResizerOptions = {
    initCallback: () => {},
    messageCallback: (msg) => {
      if (!msg.message) {
        return false
      }
      switch (msg.message.type) {
        case 'instructorSettingsButtonClicked': {
          const { assessmentId, assessmentIdentifier } = msg.message
          setIsLoadingInstructorSettings(true)
          setAssignmentIdAndIdentifier({ assessmentId, assessmentIdentifier })
          break
        }
        case 'instructorSettingsExceptionsButtonClicked': {
          const { assessmentId, assessmentIdentifier } = msg.message
          setIsLoadingInstructorSettings(true)
          getSelectedAssignmentExceptionsData({
            id: assessmentId,
            identifier: assessmentIdentifier,
            callback: () => {
              setIsLoadingInstructorSettings(false)
              openExceptionsSettingsDrawer({ dispatchSettingsAction })
            },
          })
          break
        }
        default:
          return false
      }
    },
  }

  const assignmentEditSubmitted = isActionLoaded(api, 'ASSIGNMENTS_SCHEDULES_PATCH')
  const assignmentEditHasErrors = actionHasErrors(api, 'ASSIGNMENTS_SCHEDULES_PATCH')
  const assignmentEditSucceeded = assignmentEditSubmitted && !assignmentEditHasErrors

  useEffect(() => {
    if (assignmentIdAndIdentifier) {
      getSelectedAssignmentSettingsData({
        id: assignmentIdAndIdentifier.assessmentId,
        identifier: assignmentIdAndIdentifier.assessmentIdentifier,
        callback: () => {
          setIsLoadingInstructorSettings(false)
          onAssignmentEdit()
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentIdAndIdentifier])

  const assignmentModalBody = () => (
    <>
      {isLoadingInstructorSettings && <Spinner />}
      <IframeResizer
        forwardRef={iframeRef}
        src={assignment?.startUrl}
        className="workbook-page-iframe"
        allow="microphone *; camera *;"
        allowusermedia="true"
        onMessage={iframeResizerOptions.messageCallback}
        onInit={iframeResizerOptions.initCallback}
        checkOrigin="false"
        title={assignment?.title}
      />
    </>
  )

  useEffect(() => {
    resetAction({ dispatch, action: 'ASSIGNMENTS_SCHEDULES_PATCH' })
  }, [assignment, dispatch])

  useEffect(() => {
    if (assignment && assignmentEditSucceeded) {
      iframeRef?.current?.sendMessage({ type: 'RELOAD' })
      resetAction({ dispatch, action: 'ASSIGNMENTS_SCHEDULES_PATCH' })
    }
  }, [assignment, assignmentEditSucceeded, closeModal, dispatch, dispatchSettingsAction])

  return (
    <Modal
      modalBody={assignmentModalBody()}
      modalHeader={assignment?.title}
      fullHeight
      withTheme
      open={!!assignment}
      toggle={closeModal}
    />
  )
}

export default memo(withTheme(AssignmentModal))

AssignmentModal.propTypes = {
  assignment: PropTypes.object,
  closeModal: PropTypes.func,
  courseKey: PropTypes.string,
  onAssignmentEdit: PropTypes.func,
}

AssignmentModal.defaultProps = {
  assignment: {},
  closeModal: () => {},
  onAssignmentEdit: () => {},
}
