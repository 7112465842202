import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Table } from 'reactstrap'
import { Button } from '@vitalsource/vstui'
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import moment from 'moment'
import { getIsInstructorByEmail } from 'store/selectors/courseSelectors'

const CourseInfo = ({
  course,
  closeDrawer,
  openCloneDrawer,
  toggleInstructionsModal,
  userEmail,
}) => {
  const { t } = useTranslation()

  const showCopyButton = course ? getIsInstructorByEmail(userEmail, course.instructors) : false

  const onClose = () => {
    closeDrawer()
  }

  const onOpenCloneDrawer = () => {
    openCloneDrawer(course)
  }

  const handleToggleInstructionsModal = () => {
    toggleInstructionsModal(course)
  }

  return (
    <>
      <div className="drawer-body">
        <Table borderless responsive>
          <tbody>
            <tr>
              <th scope="row">{t('courses.courseInfoDrawer.title')}</th>
              <td>{course.title}</td>
            </tr>
            <tr>
              <th scope="row">{t('courses.courseInfoDrawer.courseKey')}</th>
              <td>{course.courseKey}</td>
            </tr>
            <tr>
              <th scope="row">{t('courses.courseInfoDrawer.startDate')}</th>
              <td>{course.startDate && moment(course.startDate).format('l')}</td>
            </tr>
            <tr>
              <th scope="row">{t('courses.courseInfoDrawer.endDate')}</th>
              <td>{course.endDate && moment(course.endDate).format('l')}</td>
            </tr>
            <tr>
              <th scope="row">{t('courses.courseInfoDrawer.instructorEmail')}</th>
              <td>
                {course.instructors &&
                  course.instructors.map((instructor) => (
                    <span key={instructor.id}>
                      {instructor.email}
                      <br />
                    </span>
                  ))}
              </td>
            </tr>
          </tbody>
        </Table>

        {course && course.customer && course.customer.enableCourseCopy && (
          <Button outline onClick={handleToggleInstructionsModal}>
            <VerticalAlignBottomIcon className="button-icon" />
            {t('courses.courseInfoDrawer.download')}
          </Button>
        )}
      </div>
      <div className="bottom-actions text-right">
        {showCopyButton && course && course.customer && course.customer.enableCourseCopy && (
          <Button outline type="button" onClick={onOpenCloneDrawer}>
            <FileCopyOutlinedIcon className="button-icon" />
            {t('courses.courseInfoDrawer.clone')}
          </Button>
        )}
        <Button onClick={onClose}>{t('generics.close')}</Button>
      </div>
    </>
  )
}

CourseInfo.propTypes = {
  course: PropTypes.object,
  closeDrawer: PropTypes.func,
  openCloneDrawer: PropTypes.func,
  toggleInstructionsModal: PropTypes.func,
  userEmail: PropTypes.string,
}

export default CourseInfo
