import React, { useLayoutEffect, useState } from 'react'
import { Route, Routes, Router as ReactRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import CookiePolicyBanner from 'components/CookiePolicyBanner'
import ProtectedRouter from 'components/ProtectedRouter'
import SkipToContentLink from 'components/SkipToContentLink'
import {
  AddCourse,
  Assignment,
  Books,
  ChangePassword,
  ChooseCourseSection,
  ConfigurationError,
  Course,
  CourseOverview,
  Courses,
  Courseware,
  ErrorAccess,
  Glossary,
  Landing,
  ModuleDetail,
  NotFound,
  Page,
  Payment,
  ProfileSettings,
  Settings,
  SignIn,
  Terms,
  Unit,
  UnitDetail,
} from 'pages'

const Router = ({ history, ...props }) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  })

  useLayoutEffect(() => history.listen(setState), [history])

  return (
    <>
      <SkipToContentLink />
      <ReactRouter
        {...props}
        basename="/"
        location={state.location}
        navigationType={state.action}
        navigator={history}
      >
        <Routes>
          <Route path="courseware" element={<ProtectedRouter component={Courseware} />} />
          <Route path="courses" element={<ProtectedRouter component={Courses} />} />
          <Route path="courses/add" element={<ProtectedRouter component={AddCourse} />} />
          <Route
            path="courses/error"
            element={<ProtectedRouter component={ConfigurationError} />}
          />
          <Route
            path="courses/error-access"
            element={<ProtectedRouter component={ErrorAccess} />}
          />
          <Route path="courses/:courseKey/pay" element={<ProtectedRouter component={Payment} />} />
          <Route path="courses/:courseKey" element={<ProtectedRouter component={Course} />}>
            <Route index element={<CourseOverview />} />
            <Route path="unit/:unitId" element={<Unit />}>
              <Route index element={<UnitDetail />} />
              <Route path="module/:moduleId" element={<ModuleDetail />} />
            </Route>
            <Route path="page/:pageId" element={<Page />} />
            <Route path="books" element={<Books />} />
            <Route path="glossary" element={<Glossary />} />
            <Route path="assignment/:assignmentType/:assignmentId" element={<Assignment />} />
            <Route path="settings" element={<Settings />} />
          </Route>
          <Route path="profile" element={<ProtectedRouter component={ProfileSettings} />} />
          <Route
            path="profile/changepassword"
            element={<ProtectedRouter component={ChangePassword} />}
          />
          <Route path="tos" element={<ProtectedRouter component={Terms} />} />
          <Route
            path=":customerSlug/:vbid/choose-course-section"
            element={<ProtectedRouter component={ChooseCourseSection} />}
          />
          {process.env.REACT_APP_ALOHOMORA_ENABLED === 'true' ? (
            <>
              <Route path="/" element={<ProtectedRouter component={Landing} />} />
              <Route path=":customerSlug" element={<ProtectedRouter component={Landing} />} />
              <Route path=":customerSlug/:vbid" element={<ProtectedRouter component={Landing} />} />
              <Route path="signup" element={<ProtectedRouter component={Landing} />} />
              <Route
                path="signup/:customerSlug"
                element={<ProtectedRouter component={Landing} />}
              />
            </>
          ) : (
            <>
              <Route path="/" element={<SignIn />} />
              <Route path=":customerSlug" element={<SignIn />} />
              <Route path=":customerSlug/:vbid" element={<SignIn />} />
            </>
          )}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ReactRouter>
      <CookiePolicyBanner />
    </>
  )
}

Router.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({}),
    action: PropTypes.string,
    listen: PropTypes.func,
  }),
}

export default Router
